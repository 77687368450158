.site-header li.site-navigation__item.is-dropdown .site-navigation__dropdown {
  margin: -5px 0 0 0;
}

.product-brief__cta {
  @include breakpoint($bp--large-up) {
    top: 10%;
  }
  @include breakpoint($bp--xxlarge-up) {
    top: 8%;
  }
}

.product-full {
  &--enhanced {
    .product-full {
      &__tabs-block {
        .accordion {
          &-tabs {
            .accordion-tab {
              .accordion-tab__headings {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    &.product-full {
      .product-full__details-container {
        .product-full {
          &__addtobag-block {
            width: 100%;
          }
        }
      }
    }
  }
}

.spp-reviews__title {
  font-size: 36px;
}
