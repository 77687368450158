#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: '役に立つ(';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: '無用(';
              }
            }
          }
          .pr-rd-flag-review-container {
            line-height: 1.5;
          }
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          .pr-rd-search-container {
            @-moz-document url-prefix() {
              left: 20%;
            }
          }
        }
      }
    }
  }
}

.product__footer {
  .product-full__review-snippet {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-stars-reco-stars {
        .pr-snippet-read-and-write {
          .pr-snippet-review-count {
            &:before {
              content: 'すべて読む';
            }
          }
        }
        .pr-snippet-stars-container {
          &:before {
            content: '総合評価:';
          }
        }
      }
    }
  }
}
