.password-sent-page,
.password-reset-page {
  &__content {
    @include swap_direction(padding, 0 10px 0 10px);
  }
}

.profile-info__fieldset {
  .picker-radio {
    float: #{$ldirection};
    width: 25%;
  }
  .gender_col,
  .birthday_col {
    label {
      display: block;
    }
  }
  div.birthday_col {
    .select2-container {
      margin-bottom: 10px;
    }
  }
}

.state_container {
  label {
    display: block;
  }
}

.postal_code_container {
  .address-form {
    &__item {
      width: 30%;
      float: #{$ldirection};
      margin-#{$rdirection}: 1em;
    }
  }
}

.site-container {
  .past-purchases {
    .product__footer {
      a.notify_me {
        @include swap_direction(margin, 0);
      }
      .product-item {
        &__out-of-stock {
          top: 53px;
        }
        &__coming-soon {
          right: 0;
          position: absolute;
          top: 53px;
        }
      }
    }
  }
  .favorites-page {
    .grid--mpp {
      .product__footer {
        a.notify_me {
          top: 18px;
          @include swap_direction(margin, 0);
        }
        .product-item__out-of-stock {
          position: absolute;
          bottom: 0px;
          #{$rdirection}: 0px;
          @include swap_direction(padding, 0);
          top: 52px;
        }
      }
    }
  }
  .account-nav {
    .sidebar-profile-pic {
      float: #{$ldirection};
      #profile-pic-button {
        white-space: nowrap;
        @include breakpoint($bp--medium-down) {
          white-space: normal;
        }
      }
      .barcode-placeholder {
        margin-top: 10px;
      }
    }
    .account-utilities,
    .account-purchases {
      .sidebar-menu {
        font-size: 14px;
        .logout {
          margin: 30px 0 10px 98px;
        }
      }
    }
  }
  #product-page {
    &-livechat-hitarea {
      .lpStaticButton {
        width: 350px;
      }
    }
  }
  .order-details-page {
    .order-products {
      .cart-item__taxed {
        padding-#{$ldirection}: 3px;
      }
    }
  }
}

.order-details-page {
  .order-details {
    .order-details__item {
      width: 26%;
    }
  }
  .product-header {
    .tracking {
      width: 17.66667%;
    }
  }
}

.site-header {
  .site-header__suffix {
    width: 250px;
  }
  .site-header__menu {
    margin-left: 172px;
  }
}

form[name='address'] {
  .form_element.radio.default_ship_container {
    label {
      display: inline-block;
      margin-bottom: 10px;
    }
  }
  .state_container.address-form__item {
    max-width: 660px;
  }
  .address-form__item.city_container {
    max-width: 660px;
  }
  .form_element.postal_code_container {
    margin-bottom: 8px;
  }
}

.profile-page,
.address__content,
#address_form_container,
.add-payment-page__content {
  .adpl {
    input[type='email'].js-label-mode + label:before,
    input[type='tel'].js-label-mode + label:before,
    input[type='password'].js-label-mode + label:before,
    input[type='text'].js-label-mode + label:before {
      content: attr(data-required) attr(alt);
    }
    input[type='email'] + label:before,
    input[type='tel'] + label:before,
    input[type='password'] + label:before,
    input[type='text'] + label:before {
      content: attr(data-required) attr(placeholder);
    }
  }
}

.add-payment-page__content {
  .select2-container.expiry_year {
    margin-top: 18px;
  }
}

.order-totals {
  tr {
    &.order-details-lineitems {
      border-top: 1px solid #dbdbdb;
    }
    &.total-with-tax {
      border-top: 1px solid #000;
    }
    &.tax-above {
      border-bottom: 1px solid #000;
    }
  }
}

.profile-page {
  .adpl {
    .profile-info__required {
      margin-bottom: 10px;
    }
  }
}

.panel {
  .payment-form {
    .error_messages {
      margin-bottom: 10px;
    }
  }
}

@if $fonts_update == true {
  .account-page {
    .past-purchases {
      .product--teaser {
        .product__name {
          font-size: 14px;
          line-height: unset;
        }
        .product__mini-detail {
          font-size: 12px;
        }
        .product__images {
          @include breakpoint($bp--small-up) {
            bottom: 70px;
          }
        }
      }
    }
  }
}
