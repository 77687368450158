.margin_top_bottom {
  @include swap_direction(margin, 0.8em 0 0.8em 0);
}

.lpPoweredBy {
  display: none;
}

.site-footer {
  .site-footer--top {
    .grid-container--6 .grid--item {
      &:nth-of-type(6n + 1) {
        width: 20%;
      }
      &:nth-of-type(6n + 2) {
        width: 20%;
        @include swap_direction(margin, 0 -100% 0.5em 20%);
      }
      &:nth-of-type(6n + 3) {
        width: 20%;
        @include swap_direction(margin, 0 -100% 0.5em 40%);
      }
      &:nth-of-type(6n + 4) {
        width: 20%;
        @include swap_direction(margin, 0 -100% 0.5em 60%);
      }
      &:nth-of-type(6n + 5) {
        width: 20%;
        @include swap_direction(margin, 0 -100% 0.5em 80%);
      }
    }
    .block--footer-extra {
      .social-links {
        .social--set-icons_part {
          display: inline-block;
          margin-top: 12px;
        }
        .line__icon {
          &:before {
            content: '';
            background: url(../fonts/LINE.svg) 0 1px no-repeat;
            display: inline-block;
            width: 23px;
            height: 23px;
          }
        }
        .gplus__icon {
          &:before {
            content: '';
            background: url(../fonts/GOOGLE_PLUS.svg) 0 1px no-repeat;
            display: inline-block;
            width: 30px;
            height: 20px;
          }
        }
        .cosme__icon {
          &:before {
            content: '';
            background: url(../fonts/COSME.svg) 0 2px no-repeat;
            display: inline-block;
            width: 95px;
            height: 20px;
          }
        }
      }
    }
  }
}

.site-email-sms-signup__form {
  .form--newsletter__form-items {
    .form-type-textfield {
      &:nth-child(2) {
        display: none;
      }
    }
  }
}

.site-email-signup__contents-form {
  .form-item {
    .mobile-number {
      display: none;
    }
  }
}

.site-container {
  .product__edd {
    display: none;
  }
  .artistry--artist {
    .prod_inv_status-2,
    .prod_inv_status-3 {
      .product__footer {
        a.notify_me {
          font-size: 13px;
        }
      }
    }
  }
  .page--spp__product {
    .product--not-shoppable {
      .product__footer {
        .product__inventory-status {
          .notify_me {
            position: static;
            height: 60px;
            line-height: 64px;
            @include swap_direction(padding, 0 20px);
            width: 100%;
            font-size: 1.5em;
          }
          .coming-soon-text {
            margin: 20px 0;
          }
        }
      }
    }
  }
  .site-email-sms-signup {
    height: 58%;
    .form--newsletter {
      .error_messages {
        &.error_messages_display {
          margin: 0 0 15px 0;
          li {
            line-height: 20px;
          }
        }
      }
      &__form-items {
        .form-text {
          padding: 15px 12px;
        }
        .btn {
          height: 52px;
          line-height: 52px;
        }
      }
    }
  }
  @include breakpoint($bp--xlarge-up) {
    .custom-grid {
      .carousel--small-controls {
        .carousel-controls {
          height: 60px;
          line-height: 60px;
          bottom: 0;
        }
        .slick-slider {
          .slick-dots {
            height: 60px;
            line-height: 60px;
          }
        }
      }
      .custom-grid__item-inner {
        .trustmark-header {
          .text-block__body {
            position: relative;
            top: 28%;
          }
        }
        .trustmark-img {
          top: 32% !important;
        }
      }
    }
  }
  .collection-products__list,
  .collection-detail-formatter {
    .prod_inv {
      &_status-2,
      &_status-3 {
        .product__footer {
          a.notify_me {
            width: auto;
            line-height: 30px;
            top: 0;
          }
        }
      }
    }
  }
  .store-locator {
    .results_panel {
      .results_side {
        .sub-options {
          display: none;
        }
        .doors_results-wrapper {
          height: 682px;
        }
      }
    }
  }
}

#site-header {
  .site-header {
    &__menu {
      @include breakpoint($bp--medium-down) {
        margin-left: 0;
      }
      @include breakpoint($bp--xxlarge-up) {
        width: 864px;
        margin-left: 152px;
      }
    }
    .site-navigation {
      li {
        &.site-navigation {
          &__item {
            line-height: 58px;
          }
        }
      }
    }
  }
}

.artist--products {
  .carousel-slide {
    .product__header {
      .product__mini-detail {
        position: relative;
        z-index: 1;
      }
    }
  }
}

.product_rgn_name_below_subline {
  @if $fonts_update == true {
    font-size: 12px;
  } @else {
    font-family: 'tstar_mono_roundregular';
  }
}

.sample-products {
  .product__description-short {
    font-family: 'tstar_mono_roundregular';
  }
}

.site-email-sms-signup {
  .site-email-sms-signup__thank-you__content {
    top: 30%;
  }
}

.custom-grid {
  .unique-small-snowflake {
    .custom-grid__item-inner {
      .basic-responsive-v1 {
        height: 48%;
        background-color: $color--white;
      }
      .form--newsletter__subtitle {
        @include swap_direction(margin, 0 0 10px 0);
      }
    }
  }
}

.section-viva-glam-mac-aids-fund {
  .product--teaser--mini.product--sku-product {
    .product_content_fav_mobile {
      .product__add-to-bag {
        display: inline-block !important;
      }
    }
  }
  .artist--products__carousel {
    .product--shaded.prod_inv_status-2,
    .product--shaded.prod_inv_status-7,
    .product--shaded.prod_inv_status-3 {
      &.product--not-orderable {
        .product__footer {
          .product_content_fav_mobile {
            .product__link-to-spp {
              display: none;
            }
          }
        }
      }
    }
  }
}

#mobile-navigation {
  &.sidr {
    .field-menu {
      li {
        .menu_search {
          width: 75%;
          padding: 10px 40px;
          margin-right: 10px;
        }
        .menu_search_btn {
          background-color: #4d4d4d;
          padding: 5px 10px 10px;
          height: 36px;
          line-height: 1px;
        }
        .menu_search_img {
          position: absolute;
          top: 28px;
          font-size: 20px;
          left: 15px;
          color: #4d4d4d;
        }
        &.mm-whats-new-items-mobile-menu {
          .menu {
            .menu-item-container {
              padding-top: 0;
              .mm-whats-new-items-mobile-contents {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.new-account,
.return-user {
  &__item {
    input[type='text'],
    input[type='password'] {
      line-height: 20px;
      padding: 18px 12px;
    }
  }
}

.uncheck_email_newsletter {
  margin: 0 0 0 40px;
  color: #888;
}

.product--teaser {
  .product__subline {
    font-weight: bold;
    padding-#{$rdirection}: 85px;
    @if $fonts_update == true {
      font-size: 1rem;
    }
  }
}

.site-navigation {
  &__submenu-col {
    font-weight: bold;
    h3 {
      font-weight: bold;
    }
  }
  &__submenu-tout {
    font-weight: normal;
  }
}
/* BIS/ OOS notification overlay style */

.waitlist-iframe-wrapper {
  width: 100%;
  height: 220px;
}

.tiny-waitlist-overlay {
  height: 226px;
  margin-top: 5px;
  .waitlist_header,
  .waitlist_thankyou_heading {
    font-weight: 700;
    font-size: 16px;
  }
  .waitlist_message {
    font-size: 11px;
    margin-bottom: 5px;
  }
  .email_input {
    width: 82%;
    float: left;
    clear: none;
  }
  .submit_input {
    float: left;
    clear: none;
  }
  .input-btn {
    height: 58px;
    margin-left: 10px;
  }
  .terms_condition_cont {
    text-align: right;
    position: relative;
    top: 10px;
    width: 100%;
    float: right;
    right: 2px;
  }
}
/* End - BIS/ OOS notification overlay */

.store-locator {
  .results_panel {
    .results_side {
      .door_info {
        .distance {
          display: none;
        }
      }
    }
    .locations_map_panel {
      .infoBox {
        .location_info {
          .distance {
            display: none;
          }
        }
      }
    }
  }
}

.field-content {
  .store-locator {
    .form--search--advanced {
      .icon--search {
        padding-top: 15px;
      }
      .form--search {
        .form--inline {
          .toggle-wrapper {
            clear: both;
          }
        }
        .form-type-textfield {
          float: left;
          margin-#{$rdirection}: 8px;
        }
        .store-locator__submit {
          float: left;
          width: 218px;
        }
      }
    }
    &.store-locator-results {
      @include breakpoint($bp--largest-up) {
        .form--search--advanced {
          @include layout($grid--4) {
            @include grid-span(3, 2);
          }
        }
      }
    }
  }
}

.section-bestsellers {
  .grid--mpp__item {
    height: 535px !important;
    .product--teaser {
      min-height: 535px !important;
      .product__images {
        bottom: 75px !important;
      }
    }
  }
}

.section-trending-now-sweet-on-you,
.section-trending-now-ombre-lips {
  .grid--mpp__item {
    height: 525px !important;
    .product--teaser {
      min-height: 525px !important;
      .product__images {
        bottom: 80px !important;
      }
    }
  }
}

.collection-detail-formatter {
  .grid--mpp__item {
    .product--teaser {
      &.prod_inv_status-3 {
        .product__footer {
          .product__inventory-status {
            li.coming-soon-text {
              padding-top: 0px !important;
            }
          }
        }
      }
      .product__images {
        bottom: 80px !important;
      }
    }
    .product__shade-name {
      font-family: SmartFontUI, Osaka, ï¼­ï¼³ï¼°ã´ã·ãã¯, tstar_mono_roundregular, Helvetica, Arial, sans-serif !important;
    }
    .product--shaded {
      &.prod_inv_status-2,
      &.prod_inv_status-3,
      &.prod_inv_status-7 {
        &.product--not-orderable {
          .product__footer {
            .product_content_fav_mobile {
              .product__link-to-spp {
                display: none !important;
              }
            }
          }
        }
      }
      &.prod_inv_status-2 {
        .product__footer {
          .product__inventory-status {
            .temp-out-of-stock {
              display: block;
            }
          }
        }
      }
      &.prod_inv_status-3 {
        .product__footer {
          .product__inventory-status {
            .coming-soon {
              display: block !important;
              padding-top: 0px;
            }
          }
        }
      }
      &.prod_inv_status-7 {
        .product__footer {
          .product__inventory-status {
            .sold-out {
              display: block;
            }
          }
        }
      }
    }
  }
}

@if $fonts_update == true {
  .product__images {
    .product--teaser & {
      @include breakpoint($bp--small-up) {
        bottom: 75px;
      }
    }
  }
}

.zdc {
  &__popup {
    &--title {
      p {
        margin-bottom: 0;
      }
    }
  }
  &__dollar {
    &--buttons {
      width: 60%;
      margin-#{$ldirection}: 90px;
    }
  }
}

.gift-options-panel {
  .giftwrap {
    .sub-section {
      .btn {
        min-width: unset;
        width: auto;
      }
    }
  }
}

.viewcart {
  .column {
    .panel {
      .form-item {
        label {
          width: 7%;
          margin-bottom: 1px;
        }
      }
    }
  }
}
