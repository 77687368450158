/* Added For Pre-Order Enhancement */
.product--preorder {
  .BVRRRatingSummaryLinkWrite,
  .BVRRRatingSummaryLinkWriteFirst {
    a {
      pointer-events: none;
      opacity: 0.6;
      cursor: default;
    }
  }
  .product-full {
    &__review-snippet {
      .pr-snippet-write-review-link {
        pointer-events: none;
        opacity: 0.6;
        cursor: default;
      }
    }
  }
}

.grid--mpp__item {
  .product--preorder {
    .product--teaser {
      &__msg-preorder {
        min-height: 50px;
        display: block;
        .preorder_details {
          display: none;
        }
        .hide-shoppable-product {
          line-height: 14px;
        }
      }
    }
    &.product--not-shoppable {
      .product__inventory-status {
        li {
          &.preorder-noshop {
            @include swap_direction(padding, 0 10px);
            font-size: 14px;
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
  }
}

.preorder-panel {
  border-top: 1px solid $color--gray--lightest;
  &__viewcart-text {
    padding-top: 15px;
    .details-link {
      text-transform: none;
    }
  }
  &__order-summary {
    margin-bottom: 10px;
  }
}

.site-bag {
  &__contents-preorder-total {
    text-transform: uppercase;
    color: $color--red;
  }
}

.cart_overlay {
  &__preorderhead {
    @include swap_direction(padding, 0 15px 15px 15px);
    h3 {
      font-size: 30px;
      color: $color--red;
    }
  }
}

.cart-product-preorder {
  line-height: 21px;
}
/* Viewcart Background change for preorder */
.viewcart {
  .viewcart-panel,
  .order-summary-panel {
    padding-left: 10px;
  }
  .delivery_options {
    &__content {
      .select2-container {
        &-disabled {
          opacity: 0.3;
        }
      }
    }
  }
  .preorder-panel {
    background: $color--gray--white;
    &__viewcart-text {
      width: 75%;
    }
    .qty {
      .select2-container {
        .select2-choice {
          background: $color--gray--white;
          .select2-arrow {
            background: $color--gray--white;
          }
        }
      }
    }
    &__order-summary {
      background: $color--gray--white;
      .total {
        &.label,
        &.value {
          background: $color--gray--white;
        }
      }
    }
  }
  &-sample-message {
    margin-top: 15px;
  }
}
/* Side Panel preorder */
.checkout__sidebar {
  .preorder-panel {
    &.side-panel {
      .cart-item__total {
        width: auto;
        padding-right: 0;
        margin-left: 55.33333%;
        line-height: 20px;
      }
      .cart-item__pretext {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0);
        width: 50%;
        float: #{$rdirection};
        clear: #{$rdirection};
      }
    }
  }
}
/* Shipping Page preorder */
.preorder-msg {
  &__ship-carddetails {
    margin-bottom: 15px;
  }
  &__cod {
    width: 90%;
    padding-left: 51%;
  }
  &__gmo {
    width: 40%;
    padding-left: 41px;
  }
  &__gmo-user {
    width: 100%;
  }
  &__gift-wrap {
    width: 80%;
  }
}

.preorder-gift {
  &__head-content {
    h3 {
      float: #{$ldirection};
      width: 40%;
    }
  }
  &__text {
    float: #{$ldirection};
    width: 60%;
    margin-top: 5px;
  }
}
/* Order History preorder */
.preorder-history-panel {
  .order-info {
    &.past-purchases__item {
      .product-header {
        .product {
          width: 45%;
          margin-right: 0;
        }
        .quantity {
          width: 27%;
          margin: 0;
        }
        .price {
          width: 8%;
          float: #{$ldirection};
        }
        .preorder-details {
          width: 20%;
          float: #{$ldirection};
        }
      }
      .product--teaser {
        .product__qty {
          margin-left: 45%;
        }
      }
    }
  }
  .payment-info {
    &__preorder {
      width: 50%;
      float: #{$ldirection};
    }
  }
  .address-book-page {
    &__content {
      border-top: 1px solid $color--gray--lightest;
      padding-top: 15px;
    }
  }
  .past-purchases {
    &__item {
      .collection-id,
      .pre-order {
        width: 22%;
        float: #{$ldirection};
        padding-right: 1em;
      }
      .collection-id {
        margin-left: 22%;
      }
      .pre-order {
        margin-left: 44%;
      }
    }
  }
}

.preorder-text {
  &__content {
    color: $color--red;
  }
}

.shipments-list {
  .product-header {
    h6.preorder-details {
      .preorder-detail-item-link {
        color: $color--blue;
        text-transform: none;
      }
    }
  }
}

.preorder-history {
  &__header {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  &__subheader {
    @include swap_direction(margin, 5px 0 10px 0);
    font-size: 14px;
  }
}

.order-confirmation {
  .order_status_table_container ~ {
    .order-details-page {
      .order-details-page__content {
        display: none;
      }
    }
  }
}
